<template>
<div class="d-inline-flex p-2">
  <div class="jumbotron min-vw-50 p-3 justify-content-center">
    <h1>Register for Artists' Tools</h1><br>
    <div class="form-group">
      <label for="Name">Username</label>
      <input type="username" class="form-control" id="Name" maxlength="100" v-model="user.name">
    </div>
      <div class="form-group">
      <label for="InputEmail">Email address</label>
      <input type="email" class="form-control" id="InputEmail" maxlength="250" v-model="user.email" aria-describedby="emailHelp">
      <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
    </div>
    <div class="form-group">
      <label for="InputDate">Date of Birth</label>
      <input type="date" class="form-control datepicker" data-date-format="yyyy-mm-dd" id="InputDate" v-model="user.dob">
    </div>
    <div class="form-group">
      <label for="InputPassword">Password</label>
      <input type="password" class="form-control" id="InputPassword" maxlength="250" v-model="user.password">
    </div>
      <div class="form-group">
      <label for="Inputpassword_confirmation">Confirm Password</label>
      <input type="password" class="form-control" id="Inputpassword_confirmation" maxlength="250" v-model="user.password_confirmation">
    </div>
    <button type="submit" @click="login" class="btn btn-primary">Submit</button>
  </div>
</div>
</template>

<script>
export default {
  name: 'RegisterForm',
  props: {
    msg: String
  },
  data: () => ({
    user: {
      email: '',
      name: '',
      dob: '',
      password: '',
      password_confirmation: ''
    }
  }),
  methods: {
    login () {
      const userData = {
        email: this.user.email,
        name: this.user.name,
        dob: this.user.dob,
        password: this.user.password,
        password_confirmation: this.user.password_confirmation
      }
      this.$store.dispatch('currentUser/registerUser', userData)
    }
  }
}
</script>
